export default {
  install: (app, options) => {
    const translate = (path, interpolations) => {
      let copy = options[path];
      if (interpolations) {
        Object.keys(interpolations).forEach(key => {
          const re = new RegExp(`%{${key}}`, "g");
          // If you use String.prototype.replace and the thing you want to insert
          // begins with "$[0-9]", it will fail in Safari and FF. You should use
          // "$$" to escape one of the "$". This happens for us when passing in
          // formatted AU or US currency. https://github.com/zloirock/core-js/issues/471
          if (
            typeof interpolations[key] === "string" &&
            interpolations[key].charAt(0) === "$"
          ) {
            interpolations[key] = `$${interpolations[key]}`;
          }
          copy = copy.replace(re, interpolations[key]);
        });
      }
      return copy;
    };

    app.provide("t", translate);
    app.config.globalProperties.t = translate;
  }
};
